const flatsSharps = [
    ["A#0", "B♭0"],
    "empty",
    ["A#0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    ["A0", "B♭0"], 
    "empty", 
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty", 
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    ["A0", "B♭0"],
     "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    "empty",
    ["A0", "B♭0"],
    ["A0", "B♭0"],
    ["A0", "B♭0"],
];

export default flatsSharps